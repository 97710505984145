<template>
    <form v-if="!formSubmitted" @submit.prevent="sendInMbWork" class="form form--work">
        <div class="form__cols">
            <div class="form__col">
                <div class="form__item">
                    <label for="fio" class="form__label">Фамилия Имя Отчество <sup>*</sup></label>
                    <input type="text" class="form__input" id="fio" placeholder="Иванов Иван Иванович" v-model="workInMbForm.full_name" :class="{ 'is-invalid': workInMbForm.errors.has('full_name') }">
                    <HasError :form="workInMbForm" field="full_name"></HasError>
                </div>
                

                <!-- Гражданство -->
                <div class="form__item">
                    <label class="form__label">Гражданство <sup>*</sup></label>
                    <select class="form__select select" v-model="workInMbForm.citizenship" :class="{ 'is-invalid': workInMbForm.errors.has('citizenship') }">
                        <option selected disabled value="">-- Выберите гражданство --</option>
                        <option value="Российская Федерация">Российская Федерация</option>
                        <option value="Узбекистан">Узбекистан</option>
                        <option value="Таджикистан">Таджикистан</option>
                        <option value="Кыргызстан">Кыргызстан</option>
                    </select>
                    <HasError :form="workInMbForm" field="citizenship"></HasError>
                </div>

                <!-- Дата рождения -->
                <div class="form__item">
                    <label class="form__label">Дата рождения <sup>*</sup></label>
                    <div class="form__selects">
                        <select class="form__select select" v-model="DBday" @change="changeDayOfMonthOrYear" :class="{ 'is-invalid': workInMbForm.errors.has('date_of_birth') }">
                            <option v-for="day in daysOfMonth" :value="day">{{ day }}</option>
                        </select>
                        <select v-model="DBmonth" @change="changeDayOfMonthOrYear" class="form__select select" :class="{ 'is-invalid': workInMbForm.errors.has('date_of_birth') }">
                            <option value='01'>Январь</option>
                            <option value='02'>Февраль</option>
                            <option value='03'>Март</option>
                            <option value='04'>Апрель</option>
                            <option value='05'>Май</option>
                            <option value='06'>Июнь</option>
                            <option value='07'>Июль</option>
                            <option value='08'>Август</option>
                            <option value='09'>Сентябрь</option>
                            <option value='10'>Октябрь</option>
                            <option value='11'>Ноябрь</option>
                            <option value='12'>Декабрь</option>
                        </select>
                        <select class="form__select select" v-model="DByear" @change="changeDayOfMonthOrYear" :class="{ 'is-invalid': workInMbForm.errors.has('date_of_birth') }">
                            <option v-for="year in years" :value="year">{{ year }}</option>
                        </select>
                    </div>
                    <HasError :form="workInMbForm" field="date_of_birth"></HasError>
                </div>

                <!-- Электронная почта -->
                <div class="form__item">
                    <label for="email" class="form__label">Электронная почта</label>
                    <input type="email" class="form__input" id="email" placeholder="example@dot.com" v-model="workInMbForm.email" :class="{ 'is-invalid': workInMbForm.errors.has('email') }">
                    <HasError :form="workInMbForm" field="email"></HasError>
                </div>

                                <!-- Город рождения -->
                                <div class="form__item">
                    <label for="city_of_birth" class="form__label">Город рождения <sup>*</sup></label>
                    <input type="text" class="form__input" id="city_of_birth" placeholder="пос.Карамчи" v-model="workInMbForm.city_of_birth" :class="{ 'is-invalid': workInMbForm.errors.has('city_of_birth') }">
                    <HasError :form="workInMbForm" field="city_of_birth"></HasError>
                </div>

      
            </div>

            <div class="form__col">
                

                <div class="form__item">
                    <label class="form__label" style="line-height: 2;">В каком городе(с офисом М Булак) вы хотели бы работать? <sup>*</sup></label>
                    <select class="form__select select" v-model="workInMbForm.city" :class="{ 'is-invalid': workInMbForm.errors.has('city') }">
                        <option selected disabled value="">-- Выберите город --</option>
                        <option value="Астрахань">Астрахань</option>
                        <option value="Великий Новгород">Великий Новгород</option>
                        <option value="Владивосток">Владивосток</option>
                        <option value="Владимир">Владимир</option>
                        <option value="Воронеж">Воронеж</option>
                        <option value="Екатеринбург">Екатеринбург</option>
                        <option value="Иркутск">Иркутск</option>
                        <option value="Казань">Казань</option>
                        <option value="Калининград">Калининград</option>
                        <option value="Калуга">Калуга</option>
                        <option value="Краснодар">Краснодар</option>
                        <option value="Красноярск">Красноярск</option>
                        <option value="Липецк">Липецк</option>
                        <option value="Люберцы">Люберцы</option>
                        <option value="Магнитогорск">Магнитогорск</option>
                        <option value="Москва">Москва</option>
                        <option value="Мурманск">Мурманск</option>
                        <option value="Мытищи">Мытищи</option>
                        <option value="Нижний Новгород">Нижний Новгород</option>
                        <option value="Новокузнецк">Новокузнецк</option>
                        <option value="Новосибирск">Новосибирск</option>
                        <option value="Орехово-Зуево">Орехово-Зуево</option>
                        <option value="Пермь">Пермь</option>
                        <option value="Подольск">Подольск</option>
                        <option value="Ростов-на-Дону">Ростов-на-Дону</option>
                        <option value="Самара">Самара</option>
                        <option value="Санкт-Петербург">Санкт-Петербург</option>
                        <option value="Сургут">Сургут</option>
                        <option value="Тверь">Тверь</option>
                        <option value="Тольятти">Тольятти</option>
                        <option value="Томск">Томск</option>
                        <option value="Тюмень">Тюмень</option>
                        <option value="Уфа">Уфа</option>
                        <option value="Хабаровск">Хабаровск</option>
                        <option value="Химки">Химки</option>
                        <option value="Челябинск">Челябинск</option>
                        <option value="Южно-Сахалинск">Южно-Сахалинск</option>
                        <option value="Якутск">Якутск</option>
                        <option value="Ярославль">Ярославль</option>
                    </select>
                    <HasError :form="workInMbForm" field="city"></HasError>
                </div>

                <!-- reCAPTCHA -->
                <div class="form__item">
                    <div class="g-recaptcha" :data-sitekey="siteKey" data-expired-callback="recaptcha_expired" data-callback="recaptcha_callback"></div>
                </div>


                             <!-- Наличие разрешительных документов -->
                             <div class="form__item">
                    <label class="form__label">Наличие разрешительных документов <sup>*</sup></label>
                    <div class="form__choice">
                        <label class="form__radio radio">
                            <input type="radio" class="radio__input" name="docs" value="Патент" v-model="workInMbForm.availability_of_permitting_documents">
                            <span class="radio__txt">Патент</span>
                        </label>
                        <label class="form__radio radio">
                            <input type="radio" class="radio__input" name="docs" value="Регистрация" v-model="workInMbForm.availability_of_permitting_documents">
                            <span class="radio__txt">Регистрация</span>
                        </label>
                        <label class="form__radio radio">
                            <input type="radio" class="radio__input" name="docs" value="РВП" v-model="workInMbForm.availability_of_permitting_documents">
                            <span class="radio__txt">РВП</span>
                        </label>
                        <label class="form__radio radio">
                            <input type="radio" class="radio__input" name="docs" value="ВНЖ" v-model="workInMbForm.availability_of_permitting_documents">
                            <span class="radio__txt">ВНЖ</span>
                        </label>
                    </div>
                    <HasError :form="workInMbForm" field="availability_of_permitting_documents"></HasError>
                </div>
                          <!-- Знание языков -->
                          <div class="form__item">
                    <label class="form__label">Знание языков <sup>*</sup></label>
                    <div class="form__choice">
                        <label class="form__check check">
                            <input type="checkbox" class="check__input" value="Русский" v-model="workInMbForm.knowledge_of_languages">
                            <span class="check__txt">Русский</span>
                        </label>
                        <label class="form__check check">
                            <input type="checkbox" class="check__input" value="Киргизский" v-model="workInMbForm.knowledge_of_languages">
                            <span class="check__txt">Кыргызский</span>
                        </label>
                        <label class="form__check check">
                            <input type="checkbox" class="check__input" value="Узбекский" v-model="workInMbForm.knowledge_of_languages">
                            <span class="check__txt">Узбекский</span>
                        </label>
                        <label class="form__check check">
                            <input type="checkbox" class="check__input" value="Таджикский" v-model="workInMbForm.knowledge_of_languages">
                            <span class="check__txt">Таджикский</span>
                        </label>
                        <label class="form__check check">
                            <input type="checkbox" class="check__input" value="Другой" v-model="workInMbForm.knowledge_of_languages">
                            <span class="check__txt">Другой</span>
                        </label>
                    </div>
                    <input type="text" class="form__input" v-model="otherLanguage" v-if="workInMbForm.knowledge_of_languages.includes('Другой')" placeholder="Укажите другой язык">
                    <HasError :form="workInMbForm" field="knowledge_of_languages"></HasError>
                </div>

                <!-- Откуда вы узнали о нашей компании -->
                <div class="form__item">
                    <label class="form__label">Откуда вы узнали о нашей компании?</label>
                    <div class="form__choice">
                        <label class="form__check check">
                            <input type="checkbox" class="check__input" value="Социальные сети" v-model="workInMbForm.how_hear_about_company">
                            <span class="check__txt">Социальные сети</span>
                        </label>
                        <label class="form__check check">
                            <input type="checkbox" class="check__input" value="Сотрудники" v-model="workInMbForm.how_hear_about_company">
                            <span class="check__txt">Сотрудники</span>
                        </label>
                        <label class="form__check check">
                            <input type="checkbox" class="check__input" value="Знакомые" v-model="workInMbForm.how_hear_about_company">
                            <span class="check__txt">Знакомые</span>
                        </label>
                        <label class="form__check check">
                            <input type="checkbox" class="check__input" value="Другое" v-model="workInMbForm.how_hear_about_company">
                            <span class="check__txt">Другое</span>
                        </label>
                    </div>
                    <input type="text" class="form__input" v-model="otherSource" v-if="workInMbForm.how_hear_about_company.includes('Другое')" placeholder="Укажите другой источник">
                </div>

                
            </div>
        </div>
        <hr>
        <!-- Номер телефона -->
       
                    <PhoneInputComponent
                        :phone="workInMbForm.phone"
                        :phone_code="workInMbForm.phone_code"
                        @update:phone="workInMbForm.phone = $event"
                        @update:phone_code="workInMbForm.phone_code = $event"
                    />
                    <HasError :form="workInMbForm" field="phone"></HasError>
               

                <!-- СМС-код -->
                
                    <SmsCodeComponent
                        :phone="workInMbForm.phone"
                        :phone_code="workInMbForm.phone_code"
                        @update:smsCode="workInMbForm.sms_code = $event"
                        @update:encryptedSmsCode="workInMbForm.encrypted_sms_code = $event"
                    />
                    <HasError :form="workInMbForm" field="sms_code"></HasError>
                    
                
        

        <!-- Кнопка отправки -->
        <div class="form__item">
            <button class="form__btn btn btn--green" type="submit" :disabled="!isFormValid || isSubmitting">ОТПРАВИТЬ</button>
        </div>
    </form>

    <!-- Сообщение при успешной отправке -->
    <div v-else>
        <div style="background-color: #f6f6f6; width: 100%; padding: 2em; border-radius: 10px; margin-bottom:2em;">
            <h2>Ваша заявка успешно отправлена</h2>
            <p>Мы свяжемся с Вами в ближайшее время!</p>
        </div>
    </div>
</template>

<script>
import { Form } from 'vform';
import SmsCodeComponent from './SmsCodeComponent.vue';
import PhoneInputComponent from './PhoneInputComponent.vue';

export default {
    name: "WorkInMbForm",
    components: {
        SmsCodeComponent,
        PhoneInputComponent,
    },
    data() {
        return {
            siteKey: process.env.GOOGLE_RECAPTCHA_SITE_KEY,

            DBday: '01',
            DBmonth: '01',
            DByear: '2000',

            daysOfMonth: [],
            years: [],
            isSubmitting: false,

            workInMbForm: new Form({
                token: '',
                city: '',
                citizenship: '',
                date_of_birth: '',
                email: '',
                availability_of_permitting_documents: '',
                knowledge_of_languages: [],
                full_name: '',
                phone: '',
                phone_code: "+7",
                city_of_birth: '',
                how_hear_about_company: [],
                sms_code: '',
                encrypted_sms_code: '',
                agree: true,
            }),

            otherLanguage: '',
            otherSource: '',

            agreeWithTerm: false,
            formSubmitted: false,
        }
    },
    methods: {
        async sendInMbWork() {
    if (!this.isFormValid) {
        this.$snotify.warning('Пожалуйста, заполните все обязательные поля.', { timeout: 3000 });
        return;
    }

    // Если уже идет отправка, прерываем повторный вызов
    if (this.isSubmitting) return;

    // Блокируем кнопку
    this.isSubmitting = true;

    try {
        // Формируем дату рождения
        this.workInMbForm.date_of_birth = `${this.DBday}.${this.DBmonth}.${this.DByear}`;

        // Добавляем другие языки, если указаны
        if (this.otherLanguage && this.workInMbForm.knowledge_of_languages.includes('Другой')) {
            this.workInMbForm.knowledge_of_languages.push(this.otherLanguage);
        }

        // Добавляем другой источник, если указан
        if (this.otherSource && this.workInMbForm.how_hear_about_company.includes('Другое')) {
            this.workInMbForm.how_hear_about_company.push(this.otherSource);
        }

        const formData = {
            token: this.workInMbForm.token,
            city: this.workInMbForm.city,
            citizenship: this.workInMbForm.citizenship,
            date_of_birth: this.workInMbForm.date_of_birth,
            email: this.workInMbForm.email,
            availability_of_permitting_documents: this.workInMbForm.availability_of_permitting_documents,
            knowledge_of_languages: this.workInMbForm.knowledge_of_languages,
            full_name: this.workInMbForm.full_name,
            phone: this.workInMbForm.phone.replace(/[^0-9]/g, ''),
            phone_code: this.workInMbForm.phone_code.replace('+', ''),
            city_of_birth: this.workInMbForm.city_of_birth,
            how_hear_about_company: this.workInMbForm.how_hear_about_company,
            sms_code: String(this.workInMbForm.sms_code),
            encrypted_sms_code: this.workInMbForm.encrypted_sms_code,
            agree: this.agreeWithTerm,
        };

        const response = await axios.post('/send-mb-work-form', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });

        if (response.data.status) {
            this.$snotify.success(response.data.message, { timeout: 3000 });
            this.formSubmitted = true;
            this.workInMbForm.reset();
            // Здесь можно оставить isSubmitting = true, так как форма заменяется сообщением об успехе
        } else {
            this.$snotify.error(response.data.message, { timeout: 3000 });
            // Разблокируем кнопку при ошибке
            this.isSubmitting = false;
        }
    } catch (error) {
        console.error('Ошибка при отправке формы:', error);
        if (error.response) {
            const statusCode = error.response.status;
            const data = error.response.data;

            if (statusCode === 422) {
                if (data.errors) {
                    this.workInMbForm.errors.set(data.errors);
                    this.$snotify.error('Пожалуйста, исправьте ошибки в форме.', { timeout: 3000 });
                } else if (data.message) {
                    this.$snotify.error(data.message, { timeout: 3000 });
                    this.workInMbForm.errors.set({ sms_code: [data.message] });
                } else {
                    this.$snotify.error('Произошла ошибка при отправке данных. Пожалуйста, попробуйте снова позже.', { timeout: 3000 });
                }
            } else {
                this.$snotify.error(data.message || 'Произошла ошибка при отправке данных. Пожалуйста, попробуйте снова позже.', { timeout: 3000 });
            }
        } else {
            this.$snotify.error('Произошла ошибка при отправке данных. Пожалуйста, попробуйте снова позже.', { timeout: 3000 });
        }
        // Разблокируем кнопку при возникновении исключения
        this.isSubmitting = false;
    }
},

        changeDayOfMonthOrYear() {
            // Обновляем количество дней в месяце
            const daysInMonth = new Date(this.DByear, this.DBmonth, 0).getDate();
            this.daysOfMonth = Array.from({ length: daysInMonth }, (v, k) => k + 1);
            // Проверяем, чтобы выбранный день не превышал количество дней в месяце
            if (this.DBday > daysInMonth) {
                this.DBday = daysInMonth;
            }
            // Обновляем дату рождения
            this.workInMbForm.date_of_birth = `${this.DBday}.${this.DBmonth}.${this.DByear}`;
        },
    },
    computed: {
        isFormValid() {
            return this.workInMbForm.city &&
                this.workInMbForm.citizenship &&
                this.workInMbForm.date_of_birth &&
                this.workInMbForm.full_name &&
                this.workInMbForm.city_of_birth &&
                this.workInMbForm.availability_of_permitting_documents &&
                this.workInMbForm.knowledge_of_languages.length > 0 &&
                this.workInMbForm.phone &&
                this.workInMbForm.sms_code &&
                this.workInMbForm.encrypted_sms_code;
                //this.agreeWithTerm;
        },
    },
    mounted() {
        // Инициализация годов
        const currentYear = new Date().getFullYear();
        this.years = Array.from({ length: currentYear - 1900 + 1 }, (v, k) => currentYear - k);

        // Инициализация дней месяца
        this.changeDayOfMonthOrYear();
    },
}
</script>


<style scoped>
::v-deep .phone-separate {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}
::v-deep .phone-separate-label{
    width: 100%;
}

::v-deep .phone-separate-fields {
    margin-left: 0%;
    width: 100%;
}
::v-deep .phone-separate-fields div {
    width: 100%;
}

::v-deep .phone-separate-selector {
    padding-left: 1em;
    width: 12%;
}

::v-deep .separate-phone-field {
    width: 30%;
}

::v-deep .sms_item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
::v-deep .sms_item .form__label, .form__info{
    width: 100%;
}
::v-deep .sms_item .form__info{
    width: 100%;
}


::v-deep .separate-sms-code {
    margin-left: 0%;
}



::v-deep .sms-phone-select {
    width: 14%;
    margin-right: 1px;
    margin-left: 5%;
}

::v-deep .sms-phone-input {
    width: 50%;
}

::v-deep .sms-code-input {
    margin-right: 1% !important;
    margin-left: 6% !important;
    width: 30% !important;
}
@media (max-width: 768px) {
::v-deep .phone-separate-selector {
    width: 30%;
}
::v-deep .separate-phone-field {
    width: 95%;
}
::v-deep .form__code .form__btn {
    width: 44%;
    padding: 0;
}
::v-deep .form--work {
    padding:2em !important;
}

}

</style>
