<template>
  <div class="loan-form-new-design">
      <img src="../../images/assets/loans/add/loans_add_image.png" alt="Loan Image">
      <p class="title">М Булак - надежная помощь соотечественникам вдали от Родины!</p>
      <p class="sub-title">Анкета</p>
      <!-- Форма отображается, если showForm = true -->
      <div class="form-left-right-container" v-if="showForm">
          <div class="form-left-side" :class="{ 'isMobile': isMobileDetect === 1 }">
              <form @submit.prevent="sendAnketaForm" class="form form--get-loan">
                  <div class="form-fields-container">
                      <div class="form__item">
                          <label for="last_name" class="form__label">Фамилия</label>
                          <input type="text" class="form__input" id="last_name" v-model="loanForm.last_name">
                      </div>
                      <div class="field-validation-message">
                          <HasError :form="loanForm" field="last_name"></HasError>
                      </div>
                  </div>

                  <div class="form-fields-container">
                      <div class="form__item">
                          <label for="first_name" class="form__label">Имя</label>
                          <input type="text" class="form__input" id="first_name" v-model="loanForm.first_name">
                      </div>
                      <div class="field-validation-message">
                          <HasError :form="loanForm" field="first_name"></HasError>
                      </div>
                  </div>

                  <div class="form-fields-container">
                      <div class="form__item">
                          <label for="middle_name" class="form__label">Отчество</label>
                          <input type="text" class="form__input" id="middle_name" v-model="loanForm.middle_name">
                      </div>
                      <div class="field-validation-message">
                          <HasError :form="loanForm" field="middle_name"></HasError>
                      </div>
                  </div>

                  <div class="form-fields-container">
                      <div class="form__item">
                          <label for="date_of_birth" class="form__label">Дата рождения</label>
                          <MaskInput type="text" class="form__input" id="date_of_birth"
                              v-model="loanForm.date_of_birth" placeholder="__.__.____" mask="##.##.####" />
                      </div>
                      <div class="field-validation-message">
                          <HasError :form="loanForm" field="date_of_birth"></HasError>
                      </div>
                  </div>

                  <div class="form__item">
                      <label class="form__label">Гражданство</label>
                      <select class="form__select select" v-model="loanForm.citizenship">
                          <option value="Российская Федерация">Российская Федерация</option>
                          <option value="Узбекистан">Узбекистан</option>
                          <option value="Таджикистан">Таджикистан</option>
                          <option value="Кыргызстан">Кыргызстан</option>
                      </select>
                      <HasError :form="loanForm" field="citizenship"></HasError>
                  </div>
                  <div class="form__item">
                      <label class="form__label">Город</label>
                      <select class="form__select select" v-model="loanForm.city">
                        <option class="offices-list m-2" v-for="(city, index) in sortedCities" :key="index" :value="city.title">
                           {{ city.title }}
                        </option>
                      </select>
                      <HasError :form="loanForm" field="city"></HasError>
                  </div>

                  <PhoneInputComponent :phone="loanForm.phone" :phone_code="loanForm.phone_code"
                      @update:phone="loanForm.phone = $event" @update:phone_code="loanForm.phone_code = $event" />
                      <HasError :form="loanForm" field="phone"></HasError>


                  <!-- Скрытое поле для зашифрованного кода -->
                  <input type="hidden" v-model="loanForm.encrypted_sms_code" name="encrypted_sms_code" />

                  <!-- Дополнительные скрытые поля, если необходимо -->
                  <input type="hidden" v-model="loanForm.hidden_ya_id_client" name="hidden_ya_id_client" />
                  <input type="hidden" v-model="loanForm.hidden_ga_id_client" name="hidden_ga_id_client" />
                  <input type="hidden" v-model="loanForm.hidden_utm" name="hidden_utm" />
                  <input type="hidden" v-model="loanForm.token" name="token" />
                  <!-- Включаем компонент SmsCodeComponent -->
                  <SmsCodeComponent :phone="loanForm.phone" :phone_code="loanForm.phone_code"
                      @update:encryptedSmsCode="updateEncryptedSmsCode" @update:smsCode="updateSmsCode" />
                  <!-- Кнопка отправки анкеты -->
                  <div class="form__item send-item">
                      <button class="form__btn btn btn--green" type="submit"
                          :disabled="!isAvailableToSendSMS || !isAnketaSubmitEnabled">
                          ОТПРАВИТЬ
                      </button>
                      
                      
                      
                  </div>
                  <div class="send-item"><p style="padding: 10px 0;">
                          *Оценивайте свои финансовые возможности и риски<br>
                          <a target="_blank" href="https://cdn.molbulak.ru/data/site/docs/Obshie_usloviya_dogovora_zaima.pdf/">*Изучите все условия займа</a>
                      </p></div>                    
                  
              </form>
          </div>
         <div class="form-right-side" v-show="!isMobileDetect">
              <!--<img src="../../images/assets/loans/add/loan-snketa-right-bg.png" alt="Right Side Image">-->
          </div> 
      </div>

      <!-- Сообщение об успешной отправке -->
      <div v-if="showSuccessMessage" style="margin: 0 auto;">
          <h2>Ваша анкета отправлена!</h2>
          <p>Ваш специалист свяжется с Вами в ближайшее время.</p>
      </div>
  </div>
</template>

<style scoped>

</style>

<script>
import { Form } from 'vform';
import SmsCodeComponent from './SmsCodeComponent.vue';
import PhoneInputComponent from './PhoneInputComponent.vue';

export default {
  name: "LoanAnketa2",
  components: {
    SmsCodeComponent,
    PhoneInputComponent,
    // HasError больше не требуется здесь, так как он глобально зарегистрирован
  },
  props: ['is-mobile', 'cities'],
  data() {
    return {
      isMobileDetect: this.isMobile || false,
      showForm: true,
      showSuccessMessage: false,
      isAnketaSubmitEnabled: true, // Управление блокировкой кнопки отправки анкеты
      loanForm: new Form({
        id: null,
        last_name: null,
        first_name: null,
        middle_name: null,
        date_of_birth: null,
        citizenship: null,
        city: null,
        phone: '',
        phone_code: "+7",
        sms_code: null,
        encrypted_sms_code: null, // поле для зашифрованного кода
        hidden_ya_id_client: null,
        hidden_ga_id_client: null,
        hidden_utm: null,
        token: '',
      }),
      
    }
  },
  methods: {
    

    sendAnketaForm() {
      if (this.isAnketaSubmitEnabled) {
        this.isAnketaSubmitEnabled = false; // Блокируем кнопку отправки анкеты

        this.loanForm.post('/send-loan-anketa-form')
          .then(response => {
            if (response.data.success) {
              this.$snotify.success(response.data.message, { timeout: 3000 });
              this.showForm = false; // Скрываем форму
              this.showSuccessMessage = true; // Показываем сообщение об успешной отправке
            } else {
              this.$snotify.warning(response.data.message || "Ошибка отправки анкеты", { timeout: 3000 });
              this.isAnketaSubmitEnabled = true; // Разблокируем кнопку при ошибке
            }
          })
          .catch((error) => {
            this.$snotify.error("Ошибка отправки анкеты", { timeout: 3000 });
            this.isAnketaSubmitEnabled = true; // Разблокируем кнопку при ошибке
          });
      }
    },

    changePhoneCode() {
      this.loanForm.phone = null;
    },

    updateEncryptedSmsCode(encryptedCode) {
      this.loanForm.encrypted_sms_code = encryptedCode;
    },

    updateSmsCode(smsCode) {
      this.loanForm.sms_code = smsCode;
    },
  },
  mounted() {
    this.isMobileDetect = this.isMobile;

    
  },
  computed: {
      isAvailableToSendSMS() {
  if (
    !this.loanForm.phone ||
    !this.loanForm.first_name ||
    !this.loanForm.last_name ||
    !this.loanForm.middle_name ||
    !this.loanForm.city ||
    !this.loanForm.date_of_birth
  ) {
    return false;
  }

  const requiredLengths = {
    '+7': 15,
    '+996': 14,
    '+998': 13,
    '+992': 13,
  };

  return this.loanForm.phone.length >= (requiredLengths[this.loanForm.phone_code] || 0);
},
sortedCities() {
      return [...this.cities].sort((a, b) => {
          // Сравниваем по `order_position`
          if (a.order_position !== b.order_position) {
              return a.order_position - b.order_position;
          }
          // Если `order_position` равны, сортируем по алфавиту
          return a.title.localeCompare(b.title);
      });
  }
  }
}
</script>

