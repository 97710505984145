function loadYmaps() {
    const script = document.createElement('script');
    script.src = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=ccb7af03-5eef-4b1b-81ed-543a9aca24be';
    script.onload = () => {
        ymaps.ready(initMap);
    };
    document.head.appendChild(script);
}

function loadCities() {
    return fetch('/api/cities')
        .then(response => response.json())
        .then(cities => {
            return cities.filter(city => city.status === 'active')
                         .sort((a, b) => {
                             if (a.order_position === b.order_position) {
                                 return a.title.localeCompare(b.title);
                             }
                             return a.order_position - b.order_position;
                         });
        })
        .catch(error => console.error('Error fetching cities:', error));
}

function createCityDropdown(cities) {
    const citySelect = document.getElementById('citySelect');
    const defaultOption = document.createElement('option');
    defaultOption.value = 'all';
    defaultOption.textContent = 'Все города';
    citySelect.appendChild(defaultOption);

    cities.forEach(city => {
        const option = document.createElement('option');
        option.value = city.id;
        option.textContent = city.title;
        option.dataset.longitude = city.longitude;
        option.dataset.latitude = city.latitude;
        citySelect.appendChild(option);
    });

    citySelect.addEventListener('change', function() {
        const selectedOption = citySelect.options[citySelect.selectedIndex];
        const cityId = selectedOption.value;
        if (cityId === 'all') {
            showAllOffices();
            window.map.panTo([61.774567, 97.912722], { duration: 500, flying: true }).then(() => {
                window.map.setZoom(3, { duration: 300 });
            });
        } else {
            const longitude = parseFloat(selectedOption.dataset.longitude);
            const latitude = parseFloat(selectedOption.dataset.latitude);
            window.map.panTo([latitude, longitude], { duration: 500, flying: true }).then(() => {
                window.map.setZoom(10, { duration: 300 });
            });
            filterOfficesByCity(cityId);
        }
    });

    // Удаляем опцию "Выберите город" после первого открытия списка
    citySelect.addEventListener('click', function() {
        const defaultOption = citySelect.querySelector('option[value="select"]');
        if (defaultOption) {
            citySelect.removeChild(defaultOption);
        }
    });
}

function showAllOffices() {
    const officeItems = document.querySelectorAll('.offices-list__item');
    officeItems.forEach(item => item.style.display = 'block');
}

function filterOfficesByCity(cityId) {
    const officeItems = document.querySelectorAll('.offices-list__item');
    officeItems.forEach(item => {
        const itemCityId = item.dataset.cityId;
        if (itemCityId === cityId) {
            item.style.display = 'block';
        } else {
            item.style.display = 'none';
        }
    });
}

function displayOffices(offices, cities) {
    const officeList = document.querySelector('.offices-list');
    officeList.innerHTML = ''; // Очищаем список перед заполнением

    const cityOrder = cities.reduce((acc, city, index) => {
        acc[city.id] = index;
        return acc;
    }, {});

    offices.sort((a, b) => {
        const cityOrderA = cityOrder[a.city_id];
        const cityOrderB = cityOrder[b.city_id];
        if (cityOrderA === cityOrderB) {
            return a.name.localeCompare(b.name);
        }
        return cityOrderA - cityOrderB;
    });

    offices.forEach(office => {
        const officeItem = createOfficeItem(office);
        officeList.appendChild(officeItem);
    });

    const moscowOffices = offices.filter(office => office.city_id === 33 && office.metro);
    const spbOffices = offices.filter(office => office.city_id === 21 && office.metro);

    console.log('Москва:', moscowOffices);
    console.log('Санкт-Петербург:', spbOffices);

    //displaySpecificOffices(moscowOffices, 'offices-list--moscow');
    //displaySpecificOffices(spbOffices, 'offices-list--spb');
}

/*function displaySpecificOffices(offices, className) {
    const officeList = document.querySelector(`.${className}`);
    officeList.innerHTML = ''; // Очищаем список перед заполнением

    offices.forEach(office => {
        const officeItem = createOfficeItem(office);
        officeList.appendChild(officeItem);
    });
}*/

function createOfficeItem(office) {
    const officeItem = document.createElement('div');
    officeItem.classList.add('offices-list__item');
    officeItem.dataset.cityId = office.city_id; // Добавляем атрибут с ID города
    officeItem.dataset.latitude = office.coordinates.split(',')[0]; // Широта
    officeItem.dataset.longitude = office.coordinates.split(',')[1]; // Долгота

    const officeContent = document.createElement('div');
    officeContent.classList.add('offices-list__content');

    const officeLink = document.createElement('a');
    officeLink.classList.add('offices-list__link');
    officeLink.href = '#';

    // Добавляем обработчик клика на ссылку офиса
    officeLink.addEventListener('click', function(event) {
        event.preventDefault();
        const latitude = parseFloat(officeItem.dataset.latitude);
        const longitude = parseFloat(officeItem.dataset.longitude);

        const targetSection = document.querySelector('#map');
        if (targetSection) {
            targetSection.scrollIntoView({
                behavior: 'smooth', // Плавная прокрутка
                block: 'start' // Прокрутить к началу элемента
            });
        }
        // Перемещаем карту к выбранному офису
        window.map.panTo([latitude, longitude], { duration: 500, flying: true }).then(() => {
            window.map.setZoom(16, { duration: 300 });
        });
    });

    const officeTitle = document.createElement('h2');
    officeTitle.textContent = office.name;
    officeLink.appendChild(officeTitle);

    const officeAddress = document.createElement('p');
    officeAddress.innerHTML = `${office.address}<br> ${office.working_time ? office.working_time : ''}`;
    officeLink.appendChild(officeAddress);

    officeContent.appendChild(officeLink);

    const officePhone = document.createElement('p');
    const phoneNumbers = office.phone.split(',');
    phoneNumbers.forEach(phone => {
        const phoneLink = document.createElement('a');
        phoneLink.href = `tel:${phone.trim()}`;
        phoneLink.textContent = phone.trim();
        officePhone.appendChild(phoneLink);
        officePhone.appendChild(document.createElement('br'));
    });

    officeContent.appendChild(officePhone);

    if (office.metro && office.metro.trim() !== '') {
        const metroInfo = document.createElement('p');
        metroInfo.classList.add('icon_metro_name');
        metroInfo.setAttribute('station_id', `42_${office.metro}`);
        metroInfo.innerHTML = `
            <span class="metro_icon">
                <img src="https://cdn.molbulak.ru/data/assets/images/metro_icon.png">
            </span>
            ${office.metro}
        `;
        officeContent.appendChild(metroInfo);
    }

    officeItem.appendChild(officeContent);
    return officeItem;
}

function initMap() {
    window.map = new ymaps.Map("map", {
        center: [61.774567, 97.912722], 
        zoom: 3
    });

    fetch('/api/offices')
        .then(response => response.json())
        .then(officesData => {
            console.log('Все офисы:', officesData);
            loadCities().then(citiesData => {
                console.log('Все города:', citiesData);
                displayOffices(officesData, citiesData); 
                createCityDropdown(citiesData);

                officesData.forEach(function(office) {
                    const [lat, lng] = office.coordinates.split(',').map(coord => parseFloat(coord.trim()));
                    
                    // Разбиваем телефоны на массив, чтобы каждый телефон сделать ссылкой
                    const phoneLinks = office.phone.split(',').map(phone => {
                        const cleanedPhone = phone.trim();
                        return `<a href="tel:${cleanedPhone}">${cleanedPhone}</a>`;
                    }).join(', '); // Соединяем телефоны обратно через запятую
                
                    var placemark = new ymaps.Placemark([lat, lng], {
                        balloonContentHeader: office.name,
                        balloonContentBody: `
                            <p>${office.address}</p>
                            <p>${phoneLinks}</p>`, // Вставляем кликабельные номера телефонов
                        balloonContentFooter: ''
                    }, {
                        iconLayout: 'default#image',
                        iconImageHref: 'https://cdn.molbulak.ru/data/assets/images/location_pin_small.png', 
                        iconImageSize: [60, 60], // размеры картинки
                        iconImageOffset: [-19, -55] // смещение картинки
                    });
                    
                    map.geoObjects.add(placemark);
                });
            });
        })
        .catch(error => console.error('Error fetching office data:', error));
}

document.addEventListener("DOMContentLoaded", function() {
    if (document.getElementById('map')) {
        loadYmaps();
    }
});