<template>
    <div class="slider-wrapper" ref="sliderContainer">
      <div class="slider" 
           ref="slider" 
           @touchstart="startSwipe" 
           @touchmove="moveSwipe" 
           @touchend="endSwipe" 
           @mousedown="startDrag">
        <div class="slider-track" :style="trackStyles">
          <slot></slot> <!-- Загружаем слайды через Blade -->
        </div>
      </div>
      
      <!-- Кнопки управления -->
      <div class="slider-controls" :class="{ 'mobile': isMobile }">
        <button class="slider-nav prev" @click="prevSlide">&#10094;</button>
        <button class="slider-nav next" @click="nextSlide">&#10095;</button>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0,
      slideWidth: 0,
      visibleSlides: 4,
      totalSlides: 0,
      isMobile: false,
      startX: 0,
      startY: 0, // Добавлено для отслеживания вертикального движения
      isDragging: false,
      dragStartX: 0,
      dragOffset: 0,
      moved: false,
    };
  },
  computed: {
    trackStyles() {
      return {
        display: "flex",
        transition: this.isDragging ? "none" : "transform 0.4s ease-in-out",
        transform: `translateX(calc(-${this.currentIndex * this.slideWidth}px + ${this.dragOffset}px))`,
        width: `${this.totalSlides * this.slideWidth}px`,
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.updateSlideWidth();
      this.getSlidesCount();
      this.checkMobile();
      window.addEventListener("resize", this.updateSlideWidth);
      window.addEventListener("resize", this.checkMobile);
      window.addEventListener("mouseup", this.endDrag);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateSlideWidth);
    window.removeEventListener("resize", this.checkMobile);
    window.removeEventListener("mouseup", this.endDrag);
  },
  methods: {
    getSlidesCount() {
      this.totalSlides = this.$refs.slider.querySelectorAll(".list-links__item").length;
    },
    updateSlideWidth() {
      if (!this.$refs.slider) return;
      let screenWidth = window.innerWidth;
      this.visibleSlides = screenWidth < 768 ? 3 : 4;
      this.slideWidth = this.$refs.slider.clientWidth / this.visibleSlides;

      this.$refs.slider.querySelectorAll(".list-links__item").forEach(slide => {
        slide.style.width = `${this.slideWidth}px`;
      });
    },
    checkMobile() {
      this.isMobile = window.innerWidth < 768;
    },
    nextSlide() {
      if (this.currentIndex < this.totalSlides - this.visibleSlides) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
    },
    prevSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = this.totalSlides - this.visibleSlides;
      }
    },
    // Управление свайпом (мобильные устройства)
    startSwipe(event) {
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
      this.moved = false;
    },
    moveSwipe(event) {
      let diffX = Math.abs(event.touches[0].clientX - this.startX);
      let diffY = Math.abs(event.touches[0].clientY - this.startY);

      // Если горизонтальный свайп > 10px, считаем это движением и блокируем клик
      if (diffX > 10 && diffX > diffY) {
        this.moved = true;
        event.preventDefault(); // Блокируем прокрутку страницы
      }
    },
    endSwipe(event) {
      let diff = this.startX - event.changedTouches[0].clientX;

      if (Math.abs(diff) > 50) {
        if (diff > 0) {
          this.nextSlide();
        } else {
          this.prevSlide();
        }
      }
    },
    // Управление перетаскиванием (ПК)
    startDrag(event) {
      event.preventDefault();
      this.isDragging = true;
      this.moved = false;
      this.dragStartX = event.clientX;
      this.$refs.sliderContainer.addEventListener("mousemove", this.moveDrag);
    },
    moveDrag(event) {
      if (!this.isDragging) return;
      this.dragOffset = event.clientX - this.dragStartX;
      if (Math.abs(this.dragOffset) > 10) {
        this.moved = true; // Если перемещение больше 10px – это drag
      }
    },
    endDrag() {
      if (this.isDragging) {
        if (this.dragOffset < -50) {
          this.nextSlide();
        } else if (this.dragOffset > 50) {
          this.prevSlide();
        }
      }
      this.dragOffset = 0;
      this.isDragging = false;
      this.$refs.sliderContainer.removeEventListener("mousemove", this.moveDrag);
      
      // Сбрасываем moved через задержку, чтобы клик не блокировался
      if (this.moved) {
        setTimeout(() => {
          this.moved = false;
        }, 100);
      }
    }
  }
};
</script>
  
  <style scoped>
  /* Основной контейнер */
  .slider-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2em;
    flex-direction: column; /* Кнопки будут снизу на мобильных */
  }
  
  /* Контейнер слайдов */
  .slider {
    overflow: hidden;
    width: 100%;
    max-width: 1200px;
  }
  
  /* Лента */
  .slider-track {
    display: flex;
    will-change: transform;
    cursor: grab;
  }
  
  /* Ограничиваем ширину слайдов */
  .list-links__item {
    flex: 0 0 auto;
    text-align: center;
    padding: 20px;
    user-select: none; /* Запрещает выделение */
  cursor: grab; /* Добавляет визуальный эффект перетаскивания */

  }
  
  /* Кнопки */
  .slider-controls {
    position: absolute;
    top: 50%;
    width: 107%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    pointer-events: none;
  }
  .slider-controls.mobile {
    position: static;
    margin-top: 2em;
    justify-content: center;
  }
  
  .slider-nav {
    background: rgba(0, 0, 0, 0);
    color: rgb(121, 121, 121);
    border: none;
    padding: 10px 15px;
    font-size: 24px;
    cursor: pointer;
    z-index: 10;
    pointer-events: auto;
  }
  .slider-controls.mobile .slider-nav {
    margin: 0 10px;
  }
  
  
  /* Адаптивность */
  @media (max-width: 768px) {
    .slider {
      max-width: 900px;
    }
  }
  </style>
  